// import { GatsbyCianImg } from "../images/ourClientsPng/GatsbyCianImg"
import { GatsbyNokiaImg } from "../images/ourClientsPng/GatsbyNokiaImg"
// import { GatsbySberbankImg } from "../images/ourClientsPng/GatsbySberbankImg"
// import { GatsbyMTCImg } from "../images/ourClientsPng/GatsbyMTCImg"
import { GatsbyBig3Img } from "../images/ourClientsPng/GatsbyBig3Img"
import { GatsbyPapaJohnsImg } from "../images/ourClientsPng/GatsbyPapaJohnsImg"
// import { GatsbyVTBImg } from "../images/ourClientsPng/GatsbyVTBImg"
// import { GatsbySkImg } from "../images/ourClientsPng/GatsbySkImg"
// import { GatsbyPikImg } from "../images/ourClientsPng/GatsbyPikImg"

export const ourClients = [
  {
    logo: GatsbyNokiaImg,
    href: "",
  },
  {
    logo: GatsbyBig3Img,
    href: "",
  },
  {
    logo: GatsbyPapaJohnsImg,
    href: "",
  },
]

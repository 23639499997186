import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const GatsbyOurCasePikLogoSmall = alt => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ourCases/logoPIK.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img style={{ width: "33px" }} fluid={data.file.childImageSharp.fluid} alt={alt} />
}

import styled from "astroturf"
import React from "react"
import { trustingCompanies } from "../../../constants/trustingCompanies"
import { BootstrapContainer } from "../../../ui/BootstrapContainer"
import { Rewiew } from "./Rewiew"
import { TrustingCompanies } from "../../trustingCompanies/TrustingCompanies"

export const Reviews = () => {
  return (
    <Container>
      <ReviewWidthContainer>
        <Title>Feedbacks from our customers</Title>

        <ContentContainer>
          {trustingCompanies.map((data, ind) => (
            <Rewiew data={data} delay={350 * ind} key={ind} />
          ))}
        </ContentContainer>
      </ReviewWidthContainer>
      <TrustingCompanies />
    </Container>
  )
}

const Container = styled.section`
  background: #f7f7f7;
  //clip-path: polygon(0 0, 100% 25%, 100% 100%, 0 75%);
  /* -webkit-clip-path: polygon(0 0, 100% 25%, 100% 100%, 0 75%); */
  //-webkit-clip-path: polygon(0 0, 100% 25%, 100% 100%, 0 100%);
  padding: 100px 0;

  @media (max-width: 1200px) {
    //clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
    /* -webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%); */
    //-webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);
    //padding: 150px 0;
  }

  @media (max-width: 991px) {
    //clip-path: polygon(0 0, 100% 9%, 100% 100%, 0 91%);
    /* -webkit-clip-path: polygon(0 0, 100% 9%, 100% 100%, 0 91%); */
    //-webkit-clip-path: polygon(0 0, 100% 9%, 100% 100%, 0 100%);
  }

  @media (max-width: 690px) {
    //clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
    /* -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%); */
    //-webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
    padding: 60px 0;
  }

  @media (max-width: 370px) {
    //clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
    /* -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%); */
    //-webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
    padding: 0;
  }
`

export const ReviewWidthContainer = styled(BootstrapContainer)`
  position: relative;
  //top: -45px;

  @media (max-width: 1200px) {
    //top: -30px;
  }

  @media (max-width: 370px) {
    top: 0;
    padding: 50px 0;
  }
`

const Title = styled.div`
  display: block;
  font-size: 34px;
  font-weight: bold;
  color: #1f2328;
  margin-bottom: 60px;

  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 1.42857143;

  @media screen and (max-width: 991px) {
    margin-bottom: 40px;
  }

  @media (max-width: 420px) {
    font-size: 28px;
    margin-bottom: 30px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  align-items: stretch;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    display: none;
  }
`

import styled from "astroturf"
import React from "react"
import img1 from "../../../images/ourActivity/1.jpg"
import img2 from "../../../images/ourActivity/3.jpg"
import img3 from "../../../images/ourActivity/5.jpg"
import img4 from "../../../images/ourActivity/2.jpg"

export const ListDescription = () => {
  return (
    <Container className={"container"}>
      <Image src={img1} alt={""} />
      <ListElement>
        <svg width="16" height="1" viewBox="0 0 16 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="16" width="1" height="16" transform="rotate(90 16 0)" fill="#2669CC" />
        </svg>
        <Title>D-day</Title>
        <div />
        <Description>
          IT conference #1 in Tiraspol: 1000+ participants, 20+ speakers from Microsoft, Mail.ru, X5, Tinkoff, VTB.
        </Description>
      </ListElement>

      <Image src={img2} alt={""} />
      <ListElement>
        <svg width="16" height="1" viewBox="0 0 16 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="16" width="1" height="16" transform="rotate(90 16 0)" fill="#2669CC" />
        </svg>
        <Title>Educational courses: React Native, Js, .Net C#</Title>
        <div />
        <Description>
          We organized our own educational courses which helped more than 210 future developers.
        </Description>
      </ListElement>

      <Image src={img3} alt={""} />
      <ListElement>
        <svg width="16" height="1" viewBox="0 0 16 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="16" width="1" height="16" transform="rotate(90 16 0)" fill="#2669CC" />
        </svg>
        <Title>IT Marathon</Title>
        <div />
        <Description>
          Dex loves sport. We organized 6 km IT race with more than 200 participants from IT sphere.
        </Description>
      </ListElement>

      <Image src={img4} alt={""} />
      <ListElement>
        <svg width="16" height="1" viewBox="0 0 16 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="16" width="1" height="16" transform="rotate(90 16 0)" fill="#2669CC" />
        </svg>
        <Title>Dex Hackathon</Title>
        <div />
        <Description>We regulary organize various competitions between developers.</Description>
      </ListElement>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 48px;
  //grid-template-columns: 2%;
  //flex-wrap: wrap;

  @media (max-height: 900px) {
    grid-row-gap: 24px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 100%;
    grid-row-gap: 24px;
  }
`

const ListElement = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-column-gap: 16px;
  align-items: center;

  @media (max-height: 900px) {
    grid-row-gap: 8px;
  }
`

const Title = styled.div`
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #121e30;

  @media (max-height: 900px) {
    line-height: 1.2;
  }
`

const Description = styled.div`
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #121e30;

  @media (max-height: 900px) {
    line-height: 1.2;
  }
`

const Image = styled.img`
  display: none;

  @media (max-width: 991px) {
    display: block;
  }

  @media (max-width: 600px) {
    margin-top: 24px;
  }
`

import { useState } from "react"

export const useAnimatedStatus = (animateClassName, delay = 0) => {
  const [visible, setVisible] = useState(false)

  const onChange = isVisible => {
    if (!visible && isVisible) {
      setTimeout(() => {
        setVisible(true)
      }, delay)
    }
  }

  const className = visible ? animateClassName : ""
  const style = { visibility: visible ? "visible" : "hidden" }

  return [className, style, onChange, visible]
}

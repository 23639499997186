import aleshkov from "../images/trustingCompanies/aleshkov.jpg"
import andrei from "../images/trustingCompanies/andrei.jpg"
import tulin from "../images/trustingCompanies/tulin.jpg"
import cdn from "../images/trustingCompanies/cdn.png"
import planeta from "../images/trustingCompanies/planeta.png"
import prosocyal from "../images/trustingCompanies/prosocyal.png"

export const trustingCompanies = [
  {
    id: 3,
    author: {
      avatar: andrei,
      name: "Andrey Grishin",
      position: "CTO & Founder",
    },
    logo: cdn,
    review: `Dex turned out to be one of the few teams that had the necessary practical knowledge to carry out the complex technical tasks we envisioned.
    `,
  },
  {
    id: 2,
    author: {
      avatar: aleshkov,
      name: "Dmitry Aleshkov",
      position: "CTO",
    },
    logo: prosocyal,
    review: `From the first Skype meeting, I felt a professional approach to business. We have achieved more results than we expected. We received a lot of suggestions and advice on developing our idea.`,
  },
  {
    id: 1,
    author: {
      avatar: tulin,
      name: "Viktor Tyulin",
      position: "Director",
    },
    logo: planeta,
    review: `The choice of Dex was based on the recommendation of our partners. The main task was to create the most simple and convenient system for managing clients, and its subsequent integration with our accounting system.`,
    maxHeight: true,
  },
]

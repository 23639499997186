import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const GatsbyOurCaseNokiaLogo = alt => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ourCases/logoNokia.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <Img style={{ width: "168px" }} fluid={data.file.childImageSharp.fluid} alt={alt} />
}

import styled from "astroturf"
import React from "react"
import { BootstrapContainer } from "../../ui/BootstrapContainer"
// import { GatsbyExampleCianImg } from "../../images/example/gatsby/GatsbyExampleCianImg"
// import { GatsbyExampleSkImg } from "../../images/example/gatsby/GatsbyExampleSkImg"
import { GatsbyExampleBig3Img } from "../../images/example/gatsby/GatsbyExampleBig3Img"
import { GatsbyExampleKartinaImg } from "../../images/example/gatsby/GatsbyExampleKartinaImg"
import { GatsbyExampleNeirikaImg } from "../../images/example/gatsby/GatsbyExampleNeirikaImg"
import { GatsbyExampleLuxxyImg } from "../../images/example/gatsby/GatsbyExampleLuxxyImg"
import { GatsbyExampleCdnImg } from "../../images/example/gatsby/GatsbyExampleCdnImg"
// import { GatsbyExampleDirclubImg } from "../../images/example/gatsby/GatsbyExampleDirclubImg"
import { GatsbyExampleMetappImg } from "../../images/example/gatsby/GatsbyExampleMetappImg"
import { GatsbyExampleProsocyalImg } from "../../images/example/gatsby/GatsbyExampleProsocyalImg"
import { GatsbyExampleHelloHabitImg } from "../../images/example/gatsby/GatsbyExampleHelloHabitImg"
import { GatsbyExampleTaxiImg } from "../../images/example/gatsby/GatsbyExampleTaxiImg"
import { GatsbyExampleBoomDayImg } from "../../images/example/gatsby/GatsbyExampleBoomDayImg"
// import { GatsbyExamplePlanetaImg } from "../../images/example/gatsby/GatsbyExamplePlanetaImg"
import { GatsbyExampleNetrikaImg } from "../../images/example/gatsby/GatsbyExampleNetrikaImg"
import { GatsbyExampleMotiomeraImg } from "../../images/example/gatsby/GatsbyExampleMotiomeraImg"

export const Example = React.memo(() => {
  return (
    <Container as="section">
      <Block>
        {/* <Item href={`/Page/PortfolioMobile/big3`}> */}
        <Item>
          <GatsbyExampleBig3Img />
        </Item>
        {/* <Item href={`/Page/PortfolioMobile/kartina`}> */}
        <Item>
          <GatsbyExampleKartinaImg />
        </Item>
        <Item>
          <GatsbyExampleNeirikaImg />
        </Item>
        {/* <Item href={`/Page/PortfolioMobile/luxxy`}> */}
        <Item>
          <GatsbyExampleLuxxyImg />
        </Item>
        <Item>
          <GatsbyExampleCdnImg />
        </Item>
        {/* <Item href={`/Page/PortfolioMobile/metapp`}> */}
        <Item>
          <GatsbyExampleMetappImg />
        </Item>
        {/* <Item href={`/Page/PortfolioMobile/prosocyal`}> */}
        <Item>
          <GatsbyExampleProsocyalImg />
        </Item>
        {/* <Item href={`/Page/PortfolioMobile/hellohabit`}> */}
        <Item>
          <GatsbyExampleHelloHabitImg />
        </Item>
        {/* <Item href={`/Page/PortfolioMobile/taxi`}> */}
        <Item>
          <GatsbyExampleTaxiImg />
        </Item>
        {/* <Item href={`/Page/PortfolioMobile/boomday`}> */}
        <Item>
          <GatsbyExampleBoomDayImg />
        </Item>
        <Item>
          <GatsbyExampleNetrikaImg />
        </Item>
        {/* <Item href={`/Page/PortfolioMobile/motiomera`}> */}
        <Item>
          <GatsbyExampleMotiomeraImg />
        </Item>
      </Block>
    </Container>
  )
})

const Container = styled(BootstrapContainer)`
  padding: 50px 0;
  @media (max-width: 420px) {
    padding-top: 0;
  }
`

const Block = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
`

const Item = styled.a`
  box-sizing: border-box;
  opacity: 0.66;
  box-shadow: 0 1px 1px transparent;
  transition: top 0.3s, background-color 0.3s, box-shadow 0.3s, opacity 0.3s, -webkit-box-shadow 0.3s;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 144px;
  padding: 30px 15px;
  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.2);
    top: -2px;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.04);
  }

  @media (max-width: 420px) {
    height: auto;
  }

  & > img {
    max-width: 100%;
  }
`

import React, { useRef, useState, useEffect, useCallback } from "react"
import styled from "astroturf"
import { WidthContainer } from "../../ui/WidthContainer"
import { TrustingCompany } from "./TrustingCompany"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./slick.scss"
import { trustingCompanies } from "../../constants/trustingCompanies"
import { useWindowSize } from "../../hooks/useWindowSize"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
  arrows: true,
}

export const TrustingCompanies = () => {
  const [minItemTextHeight, setMinItemTextHeight] = useState()
  const sliderRef = useRef()
  const { width } = useWindowSize()

  useEffect(() => {
    setMinItemTextHeight()
  }, [width])

  const clickNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const clickPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  const onItemLayout = useCallback(height => {
    setMinItemTextHeight(height)
  }, [])

  return (
    <ColorfulContainer>
      <WidthContainer>
        <Title>Компании, которые нам доверяют</Title>
      </WidthContainer>
      <SliderContainer>
        <Slider {...settings} ref={sliderRef}>
          {trustingCompanies.map(data => (
            <TrustingCompany
              clickNext={clickNext}
              clickPrev={clickPrev}
              data={data}
              key={data.id}
              onLayout={onItemLayout}
              minHeight={minItemTextHeight}
            />
          ))}
        </Slider>
      </SliderContainer>
    </ColorfulContainer>
  )
}

const ColorfulContainer = styled.div`
  //background-color: #f4f7fc;
  padding: 60px 0 0;
  @media screen and (max-width: 1199px) {
    /* padding: 40px 0 16px; */
    padding: 0;
  }
  @media screen and (min-width: 992px) {
    display: none;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;

  color: #121e30;
  text-align: center;

  @media screen and (max-width: 1199px) {
    font-size: 28px;
    line-height: 34px;
    display: none;
  }
`

const SliderContainer = styled.div`
  margin-top: 50px;
  overflow: hidden;
  padding-bottom: 40px;
  @media screen and (max-width: 1199px) {
    margin-top: 0;
  }

  button {
    box-shadow: none !important;
    outline: none !important;
    &:hover,
    &:active,
    &:focus,
    &:not([disabled]):active {
      box-shadow: none !important;
      outline: none !important;
    }
  }
  /* padding: 0 30px; */
  /* padding: 30px 30px 50px;
  background-color: white; */
`

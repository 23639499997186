import { GatsbyOurCaseBig3Img } from "../images/ourCases/GatsbyOurCaseBig3Img"
import { GatsbyOurCaseHelloHabitImg } from "../images/ourCases/GatsbyOurCaseHelloHabitImg"
import { GatsbyOurCaseNokiaImg } from "../images/ourCases/GatsbyOurCaseNokiaImg"
import { GatsbyOurCaseNokiaLogo } from "../images/ourCases/GatsbyOurCaseNokiaLogo"
import { GatsbyOurCasePikImg } from "../images/ourCases/GatsbyOurCasePikImg"
import { GatsbyOurCasePikLogo } from "../images/ourCases/GatsbyOurCasePikLogo"
import { GatsbyOurCaseDiggiDealImg } from "../images/ourCases/GatsbyOurCaseDiggiDealImg"
import { GatsbyOurCaseLuxxyImg } from "../images/ourCases/GatsbyOurCaseLuxxyImg"
import { GatsbyOurCasePikLogoSmall } from "../images/ourCases/GatsbyOurCasePikLogoSmall"
import { GatsbyOurCaseNokiaLogoSmall } from "../images/ourCases/GatsbyOurCaseNokiaLogoSmall"
import { GatsbyOurCaseBig3Logo } from "../images/ourCases/GatsbyOurCaseBig3Logo"

export const ourCases = [
  {
    logo: GatsbyOurCaseBig3Logo,
    smallLogo: GatsbyOurCasePikLogoSmall,
    text: `Mobile App for 3-on-3 pro basketball league founded by Ice Cube featuring All-Stars, Hall of Famers and World Champions`,
    image: GatsbyOurCaseBig3Img,
    href: `/Page/PortfolioMobile/big3`,
  },
  {
    title: `Tracker HelloHabbit`,
    text: `Social network for developing useful skills`,
    image: GatsbyOurCaseHelloHabitImg,
    href: `/Page/PortfolioMobile/hellohabit`,
  },
  {
    // title: `NOKIA`,
    logo: GatsbyOurCaseNokiaLogo,
    smallLogo: GatsbyOurCaseNokiaLogoSmall,
    text: `Personal web account for Nokia customers`,
    image: GatsbyOurCaseNokiaImg,
    // TODO - need right link
    href: `/Page/InDevelopment`,
  },
  {
    // title: `ПИК Группа`,
    logo: GatsbyOurCasePikLogo,
    smallLogo: GatsbyOurCasePikLogoSmall,
    text: `Web service for internal document management for the largest developer in Russia`,
    // backgroundText: "gray",
    image: GatsbyOurCasePikImg,
    // TODO - need right link
    href: `/Page/InDevelopment`,
  },
  {
    title: `Marketplace DIGGI Deal`,
    text: `Specialized marketplace which allows to sell discount cards`,
    image: GatsbyOurCaseDiggiDealImg,
    // TODO - need right link
    href: `/Page/InDevelopment`,
  },
  {
    title: `LUXXY Outlet`,
    text: `Mobile App for the sale of luxury goods`,
    image: GatsbyOurCaseLuxxyImg,
    href: `/Page/PortfolioMobile/luxxy`,
  },
]

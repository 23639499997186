import styled from "astroturf/index"
import React from "react"
import { RowCost } from "./CostComponrnt/Row"
import ym from "react-yandex-metrika"

export const Cost = tagMetrica => {
  const onClickBButtons = React.useCallback(() => {
    ym("reachGoal", tagMetrica)
    const writeToUs = document.getElementById("writeToUs")
    if (writeToUs) {
      writeToUs.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return (
    <CostSection>
      <Container>
        <RowCost
          title={"Do you want to know the cost?"}
          description={`Send materials on your project and get an estimate <br/> within 1 day`}
          buttonText={"Get an estimate"}
          buttonClick={() => onClickBButtons("getEstimate")}
        />

        <RowCost
          title={"Don't know where to start?"}
          description={"We will carry out pre-project preparation for the start of your project"}
          buttonText={"Where to begin?"}
          buttonClick={() => onClickBButtons("clickHowToStart")}
        />
      </Container>
    </CostSection>
  )
}

const CostSection = styled.section`
  margin-top: 40px;

  background: url("../../images/pageHome/backgroundCountEng.jpg") no-repeat;
  display: flex;
  background-size: cover;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before {
    content: "";
    opacity: 0.78;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #232c37;
  }
  @media (max-width: 1100px) {
    background-size: 130%;
    background-position-y: 0;
  }
  @media (max-width: 991px) {
    background-size: cover;
  }

  & > div {
    z-index: 1;
    padding: 40px 15px 215px;

    @media (max-width: 1024px) {
      padding: 40px 15px 180px;
    }
    @media (max-width: 767px) {
      padding: 40px 15px 140px;
    }
    @media (max-width: 420px) {
      padding-top: 20px;
      padding-bottom: 100px;
    }
  }
`

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
`

import styled from "astroturf"

export const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: #121e30;
  @media screen and (max-width: 1199px) {
    font-size: 28px;
    line-height: 34px;
  }
`

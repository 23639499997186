import styled from "astroturf/index"
import React from "react"
import { ourCases } from "../../../constants/ourCases"
// import moreCases from "../../../images/moreArrow.svg"
import { Title } from "../../../ui/commonStyles"
import { WidthContainer } from "../../../ui/WidthContainer"
import { OurCase } from "./OurCase"

export const OurCases = () => {
  const cases = React.useMemo(() => {
    return ourCases.map((item, index) => <OurCase {...item} key={index} />)
  }, [])

  return (
    <WidthContainer>
      <Container id="cases">
        <CustomTitle>Our cases</CustomTitle>
        <Column>{cases}</Column>
      </Container>
    </WidthContainer>
  )
}

const Container = styled.div`
  padding-top: 80px;
  margin-top: 20px;
  position: relative;
  @media screen and (max-width: 1199px) {
    padding-top: 80px;
  }
  @media (max-width: 767px) {
    padding-top: 80px;
    margin-top: 0;
  }
  @media (max-width: 1300px) {
    top: -20px;
  }
`

const CustomTitle = styled(Title)`
  position: absolute;
  left: 0;
  margin-top: -20px;

  @media (max-width: 767px) {
    position: unset;
    margin-top: 0;
    text-align: center;
  }
  @media (max-width: 1300px) {
    margin-top: -20px;
  }
`

const Column = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 70px;
  flex-direction: column;
  flex: 1;

  & > :nth-child(2n + 1) {
    margin-top: 70px;
  }

  & > :nth-child(2n) {
    margin-bottom: 70px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;

    padding-top: 20px;

    & > :nth-child(n) {
      margin: 0;
    }
  }
`

// const MoreCases = styled.a`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   display: flex;
//   font-weight: 500;
//   font-size: 24px;
//   line-height: 28px;
//   color: #2669cc;
//   cursor: pointer;

//   &:hover {
//     > img {
//       transform: translateX(10px);
//     }
//   }
//   & > :not(:first-child) {
//     margin-left: 24px;
//   }

//   @media (max-width: 767px) {
//     position: unset;
//     margin-top: 40px;
//     justify-content: center;
//   }
// `

// const MoreCasesIcon = styled.img`
//   width: 48px;
//   transition: 0.1s ease-in-out;
// `

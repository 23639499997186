import styled from "astroturf"
import React from "react"
import { BootstrapContainer } from "../../ui/BootstrapContainer"
import logoGraySrc from "../../images/pageHome/logo-gray.svg"
import VisibilitySensor from "react-visibility-sensor"
import { OldRatingItem } from "./ourRating/OldRatingItem"

export const OurRating = () => {
  const [imageIsVisible, setImageIsVisible] = React.useState(false)
  const onScrollToImage = React.useCallback(
    isVisible => {
      if (!imageIsVisible && isVisible) {
        setImageIsVisible(true)
      }
    },
    [imageIsVisible]
  )

  return (
    <RatingSection as="section">
      <VisibilitySensor onChange={onScrollToImage} partialVisibility>
        <RatingImageDex alt={"Logo Dex"} src={logoGraySrc} animation={imageIsVisible} />
      </VisibilitySensor>
      <RatingContent>
        <RatingTitle>
          Our awards in key <br /> ratings
        </RatingTitle>
        <Ratings>
          <OldRatingItem
            top={"Top 30"}
            middle={"# 29"}
            bottom={"Ruward"}
            description={"Mobile App subcontracting development "}
            delay={500}
          />
          <OldRatingItem
            top={"Top 20"}
            middle={"# 16"}
            bottom={"Ruward"}
            description={"Development with blockchain technologies 2018"}
          />
          <OldRatingItem
            top={"Top 50"}
            middle={"# 39"}
            bottom={"Tagline"}
            description={"Outsoursing rating"}
            right={true}
          />
          <OldRatingItem
            top={"Top 50"}
            middle={"# 41"}
            bottom={"Tagline"}
            description={"Developers rating"}
            right={true}
            delay={500}
          />
        </Ratings>
      </RatingContent>
    </RatingSection>
  )
}

const RatingSection = styled(BootstrapContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  position: relative;
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 440px) {
    margin-bottom: 0;
  }
`

const RatingImageDex = styled.img`
  position: absolute;
  top: -72px;
  max-width: 1440px;
  width: 100%;
  @media (max-width: 1024px) {
    margin-bottom: 210px;
  }
  @media (max-width: 991px) {
    margin-bottom: 380px;
  }
  @media (max-width: 767px) {
    margin-bottom: 650px;
  }
  @media (max-width: 660px) {
    top: -30px;
    margin-bottom: 680px;
  }

  @media (max-width: 500px) {
    margin-bottom: 720px;
  }
  @media (max-width: 430px) {
    margin-bottom: 700px;
  }

  opacity: 0;
  -webkit-transform: translateY(10rem);
  transform: translateY(10rem);
  transition: all 1s ease-in-out;

  &.animation {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`

const RatingContent = styled.div`
  z-index: 1;
  margin-top: 300px;
  width: 100%;

  @media (max-width: 1199px) {
    margin-top: 200px;
  }

  @media (max-width: 991px) {
    margin-top: 130px;
  }

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`

const RatingTitle = styled.div`
  font-size: 34px;
  font-weight: bold;
  color: #212121;
  margin-bottom: 97px;
  line-height: 1.4;

  @media (max-width: 767px) {
    margin-bottom: 50px;
  }

  @media (max-width: 430px) {
    font-size: 28px;
    margin-bottom: 0;
  }

  @media (max-width: 359px) {
    font-size: 24px;
  }
`

const Ratings = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 440px) {
    padding-top: 44px;
  }

  @media screen and (max-width: 413px) {
    padding-top: 28px;
  }

  @media screen and (max-width: 374px) {
    padding-top: 18px;
  }
`

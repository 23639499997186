import React from "react"
import styled from "astroturf"
import { useAnimatedStatus } from "../../../hooks/useAnimatedStatus"
import VisibilitySensor from "react-visibility-sensor"
import "./review.scss"

export const Rewiew = ({ data, delay = 0 }) => {
  const [className, style, onChangeVisibility] = useAnimatedStatus(
    "reviews-block-left-animate",
    delay
  )

  const { author, logo, review } = data
  const { avatar, name, position } = author

  return (
    <VisibilitySensor onChange={onChangeVisibility} partialVisibility>
      {() => (
        <Container className={className} style={style}>
          <Block>
            <Header>
              <Image src={avatar} alt={"avatar"} />
              <Title>
                <Name>{name}</Name>
                <Position>{position}</Position>
              </Title>
            </Header>
            <Body>
              <Description>{review}</Description>
              <BodyImage src={logo} alt={"company logo"} />
            </Body>
          </Block>
        </Container>
      )}
    </VisibilitySensor>
  )
}

const Container = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 33.33333333%;
  display: flex;

  @media screen and (max-width: 991px) {
    width: auto;
  }
`

const Block = styled.div`
  background: white;
  padding: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
`

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
`

const Name = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #1f2328;
  line-height: 1.42857143;
`

const Position = styled.div`
  font-size: 14px;
  line-height: 1.71;
  color: #9b9b9b;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const Description = styled.div`
  font-size: 18px;
  color: #1f2328;
  margin-bottom: 20px;
  flex: 1;
  line-height: 1.42857143;
  font-weight: 500;
`

const BodyImage = styled.img`
  height: 36px;
  opacity: 0.66;
`

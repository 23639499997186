import styled from "astroturf"
import React from "react"
import Slider from "react-slick"
import { ListDescription } from "./ourActivity/ListDescription"
// import moreCases from "../../images/moreArrow.svg"
import { GatsbyOurActivityImg1 } from "../../images/ourActivity/GatsbyOurActivityImg1"
import { GatsbyOurActivityImg2 } from "../../images/ourActivity/GatsbyOurActivityImg2"
import { GatsbyOurActivityImg3 } from "../../images/ourActivity/GatsbyOurActivityImg3"
import { GatsbyOurActivityImg4 } from "../../images/ourActivity/GatsbyOurActivityImg4"
import { GatsbyOurActivityImg5 } from "../../images/ourActivity/GatsbyOurActivityImg5"

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: Math.round(typeof window === "object" ? window.innerWidth / 480 : 4),
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
}

export const OurActivity = () => {
  return (
    <Wrapper id="activity">
      <Container id="activity">
        <div className={"container"}>
          <Title>Our Events</Title>
          <Description>Dex organizes the most useful and large-scale events in our country</Description>
        </div>
        <ContainerSlider>
          <Slider {...settings}>
            <GatsbyOurActivityImg1 />
            <GatsbyOurActivityImg2 />
            <GatsbyOurActivityImg3 />
            <GatsbyOurActivityImg4 />
            <GatsbyOurActivityImg5 />
          </Slider>
        </ContainerSlider>

        <ListDescription />
      </Container>
    </Wrapper>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  @media (max-width: 600px) {
    padding-bottom: 40px;
  }
`

const Wrapper = styled.div`
  padding-top: 20px;
`

const Title = styled.h2`
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #121e30;

  @media (max-height: 900px) {
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    font-size: 28px;
    margin-bottom: 10px;
  }
`

const Description = styled.h3`
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #121e30;

  margin-top: 10px;
  margin-bottom: 48px;

  @media (max-height: 900px) {
    margin-bottom: 30px;
  }

  @media (max-width: 600px) {
    margin-bottom: 8px;
    font-size: 16px;
  }
`

const ContainerSlider = styled.div`
  margin-bottom: 48px;

  @media (max-width: 991px) {
    display: none;
  }

  img {
    height: 280px;
    width: 460px !important;
    margin: 0 10px;
  }
`

// const Link = styled.a`
//   font-family: "Gilroy", sans-serif;
//   font-weight: 500;
//   font-size: 24px;
//   line-height: 28px;
//   color: #2669cc;

//   margin-top: 48px;
//   text-align: center;

//   img {
//     margin-left: 20px;
//   }

//   &:hover {
//     > img {
//       transform: translateX(10px);
//     }
//   }

//   @media (max-height: 900px) {
//     margin-top: 24px;
//   }
// `

// const MoreCasesIcon = styled.img`
//   width: 48px;
//   transition: 0.1s ease-in-out;
// `
